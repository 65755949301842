
.news-single {
  .news-img-wrap {
    .outer {
      max-width: 400px;

      margin-bottom: (@line-height-computed/2);
      margin-left: (@line-height-computed/2);

      .image {
        .news-img-caption {
          font-size: @font-size-small;
        }
      }
    }
  }
}

.news-list-view {
  > .media-list {
    margin: 0;
    padding: 0;

    > li.media {
      padding-bottom: 7px;
      margin-bottom: 7px;
      margin-top: 7px;

      border-bottom: 1px solid @gray;

      &:before {
        content: inherit;
        border: none;
        display: inherit;
        left: auto;
        top: auto;
        position: inherit;
      }

      &:first-child {
        margin-top: 0;

        > .dateBlock {
          > span {
            &.dayMonthValue {
              .hide();
            }
            &.dayMonthShort {
              .show();
            }
          }
        }
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }

      .media-heading {
        &,
        & > a {
          font-size: @font-size-base;
          font-weight: bold;
        }
      }

      .media-body {
        h3 {
          text-transform: none;
          font-size: @font-size-base;
          color: @text-color;
          font-weight: bold;
          text-align: left;
          margin: 0;
          padding: 0;

          border-bottom: 0;

          a {
            color: @text-color;
            text-decoration: none;
          }
        }

        a.more {
          color: @link-color;
          margin-left: .2em;
          padding-left: .5em;
          border-left: 1px solid @link-color;
        }
      }
    }
  }
}