.photoDatabase-list {
  display: table;
  width: 100%;

  > .row {
    display: table-row;
    margin: 0;

    > .photoDatabase-item {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      float: none;

      padding-bottom: (@line-height-computed/2);

      .photoDatabase-item-copyright {
        font-weight: bold;
        margin-bottom: 0;
        margin-top: (@line-height-computed/4);
      }
    }
  }
}