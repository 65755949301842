/*------------ MONTH ------------*/

.tx-cal-controller {
  .list-view {
    .table {
      tr:first-child {
        td {
          border-top: 0;
        }
      }
    }
  }
}

.tx-cal-controller {
  .list-view {
    .event-title {
      &, a {
        font-weight: bold;
      }
    }
  }

  tr.event-odd {
    td, th {
      background-color: @gray-lighter;
      text-align: left;
    }
  }

  td.dateCol {
    background-color: #ffffff;
    width: 60px;

    .dateto {
      margin: .5em 0;
      text-align: center;
    }

    .date {
      &.startdate,
      &.enddate {
        .day,
        .year {
          text-align: center;
          padding: 2px 5px;
          display: block;
          border: 1px solid #646968;
          white-space: nowrap;
        }

        .day {
          .border-top-radius(5px);
          background-color: white;
        }

        .year {
          .border-bottom-radius(5px);

          #gradient > .vertical(lighten(@dehoga-blue, 10%), @dehoga-blue);

          border-top: 0;
          color: #ffffff;
        }
      }
    }
  }

  td.dateCol, .dateOverview {
    text-align: right;
  }

  .dateOverview {
    .date, .dateto {
      display: inline;
    }

    .dateto {
      padding-right: .3em;
    }

    .date .day {
      padding-right: .3em;
    }
  }
}

.tx-cal-controller .month-weeknums {
  background-color: @gray-lighter;
}

.tx-cal-controller .month-large .month-weeknums {
  width:         2em;
  border: 0;
  background-color: inherit;
  font-size: smaller;
  vertical-align: top;
  padding-top: 14px;
  line-height: 1;
}

.tx-cal-controller .month-weekdays {
  border-bottom:    1px solid @gray-light;
  background-color: @gray-lighter;
}

.tx-cal-controller .month-small a:link,
.tx-cal-controller .monthoff a:visited {
  color: #000000;
}

.tx-cal-controller .month-small th,
.tx-cal-controller .month-small td {
  padding:    2px;
  width:      22px;
  font-size:  10px;
  text-align: right;
}

.tx-cal-controller .month-small,
.tx-cal-controller .month-medium,
.tx-cal-controller .month-large {
  border-collapse: collapse;

  @media (max-width: @screen-xs-max) {
    display: none;
  }
}

.tx-cal-controller .month-small td,
.tx-cal-controller .month-medium td {
  border: 1px solid @gray-light;
}

.tx-cal-controller .month-large td {
  border-left:    1px solid @gray-light;
  border-right:   1px solid @gray-light;
  vertical-align: bottom;
  padding:        1px;
}

.tx-cal-controller .month-large tr.days td {
  border: 1px solid @gray-light;
}

.tx-cal-controller .month-medium tbody tr:last-child th,
.tx-cal-controller .month-large tbody tr:last-child td {
  border-bottom: 1px solid @gray-light;
}

.tx-cal-controller .month-small th,
.tx-cal-controller .month-medium th,
.tx-cal-controller .month-large th {
  text-align:       center;
  font-weight:      normal;
  font-size:        small;
  line-height:      2em;
  border: 0;
  background-color: inherit;
}

.tx-cal-controller .month-large .weeknum {
  width: 22px;
}

.tx-cal-controller .month-large .daylink {
  font-weight: bold;
}

.tx-cal-controller .month-large .day {
  height:         4em;
  vertical-align: top;
  text-align:     left;
}

.tx-cal-controller .month-large div.vevent,
.tx-cal-controller .month-large div.vtodo {
  overflow:    hidden;
  white-space: nowrap;

  margin:      1px;

  &, & a {
    color: @text-color;
  }
}

.tx-cal-controller .month-large .dayNum,
.tx-cal-controller .month-medium .dayNum {
  background-color: @gray-lighter;
  border-bottom:    none;
  text-align:       right;
  font-size:        xx-small;
  padding-right:    1px;
}

.tx-cal-controller .monthWeekend {
  background-color: #FFFFEF;
}

.tx-cal-controller .monthCurrentWeek {
}

.tx-cal-controller .month-small th a:link,
.tx-cal-controller .month-small th a:visited,
.tx-cal-controller .month-medium th a:link,
.tx-cal-controller .month-medium th a:visited,
.tx-cal-controller .month-large th a:link,
.tx-cal-controller .month-large th a:visited {
  font-weight: bold;
  color:       #000000;
}

.tx-cal-controller .monthView .currentDay {
  background:   #FFF7D7 none repeat scroll 0% !important;
  border-color: #556677 rgb(170, 187, 204) rgb(170, 187, 204) rgb(85, 102, 119) !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.tx-cal-controller {
  .weekday0,
  .weekday1,
  .weekday2,
  .weekday3,
  .weekday4,
  .weekday5,
  .weekday6 {
    &.empty {
      height: 2em;
    }
  }

  .emptyWeek {
    .weekday {
      height: 2em;
    }
  }

  .previous-button {
    margin-left: 122px;
    float: left;

    @media (max-width: @screen-xs-max) {
      margin-left: 0;
    }
  }
  .next-button {
    margin-right: 102px;
    float: right;

    @media (max-width: @screen-xs-max) {
      margin-right: 0;
    }
  }

  .previous-button, .next-button {
    margin-top:    7px;
    margin-bottom: 2px;

    .glyphicon {
      font-size: 18px;
    }
  }

  h1 {
    text-align: center;
  }

  #calendar-organizer h1,
  #calendar-location h1,
  #calendar-event h1 {
    text-align: left;
  }
}

.tx-cal-controller .weekday0,
.tx-cal-controller .weekday6 {
  background-color: #F8F9F8;
}

.tx-cal-controller .month-medium {
  margin:          1em auto 2em;
  border-collapse: collapse;
  border-spacing:  0px;
  width:           80%;
}

.tx-cal-controller .month-medium th,
.tx-cal-controller .month-medium td {
  height:         2em;
  width:          5%;
  text-align:     center;
  vertical-align: middle;

  &.withEventDay {
    background-color: @brand-primary;

    &, & a {
      color: #fff;
    }
  }
}

.tx-cal-controller .month-medium td {
  border: 1px solid @gray-light;
}

#month-footer {
  text-align: center;
  margin-top: 10px;
}

/*------------ YEAR ------------*/

.tx-cal-controller .yearview h1 {
  text-align: center;
}

.tx-cal-controller .year-table,
.tx-cal-controller .month-large {
  clear:            both;
  width:            100%;
  background-color: #FFFFFF;
  table-layout:     fixed;
}

.tx-cal-controller .year-table .year-month {
  vertical-align: top;
  text-align:     center;
}

.tx-cal-controller .year-table .year-month + .year-month + .year-month {
  padding-right: 0px;
}

.tx-cal-controller .month-large div.vtodo a:link span.popup, .tx-cal-controller .month-large div.vtodo a:visited span.popup, .tx-cal-controller .month-medium span.popup {
  display: none;
}

/*---------------- DAY & WEEK VIEW ------------------*/
.tx-cal-controller .dayview,
.tx-cal-controller .weekview {
  border-left: 5px solid @gray-lighter;
  clear:       both;
}

.tx-cal-controller .gridcontainer {
  background:          #f8f8f8;
  position:            relative;
  -webkit-user-select: none;
  -khtml-user-select:  none;
  overflow-y:          visible;
  height:              411px;
  border-top:          10px solid @gray-lighter;
}

.tx-cal-controller .gridcontainertop {
  table-layout:     fixed;
  width:            100%;
  background-color: @gray-lighter;
  overflow:         hidden;
  font-size:        11px;
  line-height:      14px;
  border-bottom:    10px solid @gray-lighter;
}

.tx-cal-controller .gridcontainertop .allday {
  table-layout: fixed;
  width:        100%;
}

.tx-cal-controller .gridcontainertop td {
  height:           20px;
  vertical-align:   top;
  background-color: #FFFFFF;
}

.tx-cal-controller .gridcontainertop .daynames th {
  text-align: center;
}

.tx-cal-controller .gridcontainertop .daynames th.dummy {
  width: 15px;
}

.tx-cal-controller .gridcontainertop .daynames td {
  width:            40px;
  background-color: @gray-lighter;
}

.tx-cal-controller .scrolltime {
  position:      relative;
  overflow-y:    scroll;
  overflow-x:    hidden;
  border-top:    1px solid @gray-lighter;
  border-left:   0 none;
  border-bottom: 1px solid @gray-lighter;
  padding:       0;
  font-size:     11px;
  line-height:   normal;
  height:        370px;
}

.tx-cal-controller .scrolltime .timetable {
  background-color: #fff;
  table-layout:     fixed;
  width:            100%;
  margin-top:       -1px;
  border-bottom:    1px solid #bcf;
}

.tx-cal-controller .scrolltime .timetable .width {
  width: 40px;
}

.tx-cal-controller .scrolltime .timetable .wrapper {
  position:    relative;
  margin-left: 1px;
  top:         3px;
}

.tx-cal-controller .scrolltime .timetable .wrapper .hours {
  position: absolute;
  width:    100%;
}

.tx-cal-controller .scrolltime .timetable .wrapper .hours .cell {
  height: 42px;
}

.tx-cal-controller .scrolltime .timetable .wrapper .hours .cell .line {
  border-bottom: 1px dotted @gray;
  border-top:    1px solid @gray;
  font-size:     1px;
  height:        20px;
  margin-bottom: 20px;
}

.tx-cal-controller .scrolltime .timetable .times {
  background-color: @gray-lighter;
  color:            #666;
  padding:          1px 0 0 0;
  text-align:       right;
  vertical-align:   top;
}

.tx-cal-controller .scrolltime .timetable .times div {
  height: 42px;
}

.tx-cal-controller .scrolltime .timetable .times div.timeborder {
  height:        41px;
  padding-top:   5px;
  padding-right: 5px;
}

.tx-cal-controller .scrolltime .timetable .event-column {
  border-left:    1px solid @gray;
  vertical-align: top;
}

.tx-cal-controller .scrolltime .timetable .event-column .wrapper {
  position:     relative;
  cursor:       default;
  margin-right: 10px;
}

/*------------ GENERAL Category styles ------------*/

.tx-cal-controller div.default_catheader dl,
.tx-cal-controller div.default_catheader_allday,
.tx-cal-controller div.yellow_catheader dl,
.tx-cal-controller div.yellow_catheader_allday,
.tx-cal-controller div.orange_catheader dl,
.tx-cal-controller div.orange_catheader_allday,
.tx-cal-controller div.red_catheader dl,
.tx-cal-controller div.red_catheader_allday,
.tx-cal-controller div.pink_catheader dl,
.tx-cal-controller div.pink_catheader_allday,
.tx-cal-controller div.blue_catheader dl,
.tx-cal-controller div.blue_catheader_allday,
.tx-cal-controller div.green_catheader dl,
.tx-cal-controller div.green_catheader_allday,
.tx-cal-controller div.brown_catheader dl,
.tx-cal-controller div.brown_catheader_allday,
.tx-cal-controller div.grey_catheader dl,
.tx-cal-controller div.grey_catheader_allday,
.tx-cal-controller div.fnb_header dl,
.tx-cal-controller div.fnb_header_allday {
  padding: 2px;
  margin:  1px;
}

.tx-cal-controller div.default_catheader dt,
.tx-cal-controller div.yellow_catheader dt,
.tx-cal-controller div.orange_catheader dt,
.tx-cal-controller div.red_catheader dt,
.tx-cal-controller div.pink_catheader dt,
.tx-cal-controller div.blue_catheader dt,
.tx-cal-controller div.green_catheader dt,
.tx-cal-controller div.brown_catheader dt,
.tx-cal-controller div.grey_catheader dt,
.tx-cal-controller div.fnb_header dt {
  font-size: xx-small;
}

.tx-cal-controller div.default_catheader dd,
.tx-cal-controller div.yellow_catheader dd,
.tx-cal-controller div.orange_catheader dd,
.tx-cal-controller div.red_catheader dd,
.tx-cal-controller div.pink_catheader dd,
.tx-cal-controller div.blue_catheader dd,
.tx-cal-controller div.green_catheader dd,
.tx-cal-controller div.brown_catheader dd,
.tx-cal-controller div.grey_catheader dd,
.tx-cal-controller div.fnb_header {
  white-space: nowrap;
}

.tx-cal-controller .alldays .vevent .default_catheader_text,
.tx-cal-controller .alldays .vevent .blue_catheader_text,
.tx-cal-controller .alldays .vtodo .default_catheader_text,
.tx-cal-controller .alldays .vtodo .blue_catheader_text,
.tx-cal-controller .alldays .vevent .yellow_catheader_text,
.tx-cal-controller .alldays .vtodo .yellow_catheader_text,
.tx-cal-controller .alldays .vevent .orange_catheader_text,
.tx-cal-controller .alldays .vtodo .orange_catheader_text,
.tx-cal-controller .alldays .vevent .red_catheader_text,
.tx-cal-controller .alldays .vtodo .red_catheader_text,
.tx-cal-controller .alldays .vevent .pink_catheader_text,
.tx-cal-controller .alldays .vtodo .pink_catheader_text,
.tx-cal-controller .alldays .vevent .green_catheader_text,
.tx-cal-controller .alldays .vtodo .green_catheader_text,
.tx-cal-controller .alldays .vevent .brown_catheader_text,
.tx-cal-controller .alldays .vtodo .brown_catheader_text,
.tx-cal-controller .alldays .vevent .grey_catheader_text,
.tx-cal-controller .alldays .vtodo .grey_catheader_text,
.tx-cal-controller .alldays .vevent .fnb_header_text,
.tx-cal-controller .alldays .vtodo .fnb_header_text,
.tx-cal-controller .allday.vevent span:first-child,
.tx-cal-controller .allday.vtodo span:first-child {
  color: #FFFFFF;
}

/* available colors: yellow, orange, red, pink, blue (default), green, brown, grey


/*------------ BLUE (DEFAULT) ------------*/

.tx-cal-controller div.default_catheader dl,
.tx-cal-controller div.default_catheader_allday,
.tx-cal-controller div.blue_catheader dl,
.tx-cal-controller div.blue_catheader_allday {
  border:           1px solid #646968;

  .border-top-radius(5px);
  .border-bottom-radius(5px);

  background-color: @dehoga-blue;
  #gradient > .vertical(lighten(@dehoga-blue, 10%), @dehoga-blue);
  color:            #FFFFFF;

  padding: 2px 5px;
}

.tx-cal-controller .default_catheader_allday a .summary,
.tx-cal-controller .default_catheader_allday a:visited .summary,
.tx-cal-controller .default_catheader dd a,
.tx-cal-controller .default_catheader dd a:visited,
.tx-cal-controller .blue_catheader_allday a .summary,
.tx-cal-controller .blue_catheader_allday a:visited .summary,
.tx-cal-controller .blue_catheader dd a,
.tx-cal-controller .blue_catheader dd a:visited {
  color: #FFFFFF !important;
}

/*------------ GENERAL ------------*/

.tx-cal-controller {
  .list-view {
    margin-top: 1em;

    ul.media-list {
      margin:  0;
      padding: 0;

      > li.media {
        padding-bottom: 7px;
        margin-bottom:  7px;
        margin-top:     7px;

        border-bottom:  1px solid @gray-lighter;

        .teaser,
        .location {
          .hidden();
        }

        &:before {
          content:  inherit;
          border:   none;
          display:  inherit;
          left:     auto;
          top:      auto;
          position: inherit;
        }

        > .pull-left {
          color:            #fff;
          background-color: @brand-primary;
          width:            60px;
          padding:          7px 0;

          > span {
            text-align:  center;
            display:     block;
            white-space: nowrap;

            padding:     0 7px;

            &.dayMonthShort {
              text-transform: uppercase;
            }

            &.day {
              font-weight: bold;
            }
          }
        }

        > .media-body {
          h3 {
            color:          @text-color;
            font-size:      @font-size-base;
            text-transform: inherit;
            font-weight:    bold;
            border-bottom:  0;
            padding-bottom: 0;
            text-align:     left;
            margin:         0;
            margin-bottom:  (@line-height-computed/2);

            a {
              color: @link-color;
            }
          }

          p:last-child {
            margin-bottom: 0;
          }

          span.more a {
            color:        @link-color;
            margin-left:  .2em;
            padding-left: .5em;
            border-left:  1px solid @link-color;
          }
        }

        &:first-child {
          margin-top: 0;

          > .pull-left {
            > span {
              &.dayMonthValue {
                .hide();
              }
              &.dayMonthShort {
                .show();
              }
            }
          }
        }

        &:last-child {
          border-bottom:  0;
          margin-bottom:  0;
          padding-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  #p5 {
    .tx-cal-controller {
      .list-view {
        ul.media-list {
          > li.media {
            display:        none;

            border-bottom:  0;
            padding-bottom: 0;
            margin-bottom:  0;

            &:first-child {
              display: block;
            }
          }
        }
      }
    }
  }
}