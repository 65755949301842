.treeview,
.treeview ul {
  &, ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul {
    background-color: white;
    margin-top: 4px;
  }

  .hitarea {
    background: url(/typo3conf/ext/dehoga_template/Resources/Public/Images/treeview-default.gif) -64px -25px no-repeat;
    height: 16px;
    width: 16px;
    margin-left: -16px;
    float: left;
    cursor: pointer;
  }

  li {
    margin: 0;
    padding: 2px 0pt 2px 95px;

    background: url(/typo3conf/ext/dehoga_template/Resources/Public/Images/treeview-default-line.gif) 0 0 no-repeat;

    &.collapsable,
    &.expandable {
      background-position: 0 -176px;
    }

    &.lastCollapsable,
    &.lastExpandable {
      background-image: url(/typo3conf/ext/dehoga_template/Resources/Public/Images/treeview-default.gif);
    }

    &:last-child,
    &.last {
      background-position: 0 -1766px
    }

    &.lastCollapsable {
      background-position: 0 -111px
    }

    &.lastExpandable {
      background-position: -32px -67px
    }

  }

  a.selected {
    background-color: #eee;
  }

  .hover { color: red; cursor: pointer; }

  .expandable-hitarea { background-position: -80px -3px; }

  div.lastCollapsable-hitarea,
  div.lastExpandable-hitarea {
    background-position: 0;
  }

  .placeholder {
    background: url(/typo3conf/ext/dehoga_template/Resources/Public/Images/ajax-loader.gif) 0 0 no-repeat;
    height: 16px;
    width: 16px;
    display: block;
  }
}
/* fix for IE6 */
* html .hitarea {
  display: inline;
  float:none;
}

#treecontrol { margin: 1em 0; display: none; }