//
// Tables
// --------------------------------------------------


table {
  background-color: @table-bg;
}
th {
  text-align: left;
}

table {
  th, td {
    &.align-left {
      text-align: left;
    }
    &.align-center {
      text-align: center;
    }
    &.align-right {
      text-align: right;
    }
  }
}

// Baseline styles
.htmlarea-content-body table,
.ce-table,
.contenttable,
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: @line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-cell-padding;
        line-height: @line-height-base;
        vertical-align: top;
        border-top: 1px solid @table-border-color;
      }
    }
  }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
        background-color: @brand-primary;
        color: white;

        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid @table-border-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @table-border-color;
  }

  // Nesting
  .table {
    background-color: @body-bg;
  }
}

// Condensed table w/ half padding
.htmlarea-content-body table,
.ce-table,
.contenttable,
.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-condensed-cell-padding;
      }
    }
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.
.ce-textpic table,
.htmlarea-content-body table,
.ce-table,
.contenttable,
.table-bordered {
  border: 1px solid @table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid @table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.ce-textpic table,
.htmlarea-content-body table,
.ce-table,
.contenttable,
.table-striped {
  > tbody > tr:nth-child(odd) {
    > td {
      background-color: @table-bg-accent;
    }
    > th {
      background-color: @brand-primary;
    }
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: @table-bg-hover;
    }
  }
}


// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
.table-row-variant(active; @table-bg-active);
.table-row-variant(success; @state-success-bg);
.table-row-variant(info; @state-info-bg);
.table-row-variant(warning; @state-warning-bg);
.table-row-variant(danger; @state-danger-bg);

.table-row-variant(e("blue"); @dehoga-blue; white);
.table-row-variant(blue-light; @dehoga-blue-light);
.table-row-variant(e("orange"); @dehoga-orange; white);
.table-row-variant(e("gray"); @gray; white);
.table-row-variant(e("gray-light"); @gray-light);

.table-last-td-right {
  tr {
    > td.td-last {
      text-align: right;
    }
  }
}

.table-all-td-except-first-right {
  tr {
    > td {
      text-align: right;

      &.td-0 {
        text-align: left;
      }
    }
  }
}

.table-last-td-span2 {
  tr {
    > td.td-last {
      width: percentage((2 / @grid-columns));
    }
  }
}

.table-last-td-span3 {
  tr {
    > td.td-last {
      width: percentage((3 / @grid-columns));
    }
  }
}

.table-last-td-span4 {
  tr {
    > td.td-last {
      width: percentage((4 / @grid-columns));
    }
  }
}

.table-last-td-span5 {
  tr {
    > td.td-last {
      width: percentage((5 / @grid-columns));
    }
  }
}

.table-last-td-span6 {
  tr {
    > td.td-last {
      width: percentage((6 / @grid-columns));
    }
  }
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.
.csc-default table,
.table-responsive {
  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    margin-bottom: (@line-height-computed * 0.75);
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;
    -webkit-overflow-scrolling: touch;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}
