.dateBlock {
  color: #fff;
  background-color: @brand-primary;
  width: 60px;
  padding: 7px 0;

  > span {
    text-align: center;
    display: block;
    white-space: nowrap;

    padding: 0 7px;

    &.dayMonthShort {
      text-transform: uppercase;
    }

    &.day {
      font-weight: bold;
    }
  }
}