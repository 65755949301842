//
// Wells
// --------------------------------------------------


// Base class
.csc-frame-frame1, // gray box
.frame-20, // gray box
.frame-21, // gray box with default header
.frame-22, // gray box only border
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: (@line-height-computed/2);
  background-color: @well-bg;
  border: 1px solid @well-border;
  border-radius: @border-radius-base;
  //.box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// Sizes
.well-lg {
  padding: 24px;
  border-radius: @border-radius-large;
}

.csc-frame-frame1,
.frame-20, // gray box
.frame-21, // gray box with default header
.frame-22, // gray box only border
.well-sm {
  padding: 9px;
  border-radius: @border-radius-small;
}

.csc-frame-frame1,
.frame-20 { // gray box
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-size: @font-size-base;
    color: @text-color;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: (@line-height-computed/2);
  }
}

.frame-22 {
  background-color: inherit;
}

.csc-frame-frame1,
.frame-20,
.frame-21,
.frame-22 {
  .csc-textpic {
    .csc-textpic-imagewrap {
      .csc-textpic-last {
        margin-bottom: 0;
      }
    }
  }

  ul.ce-uploads,
  ul.csc-uploads {
    margin-bottom: 0;
    padding: 0;

    > li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}