.csc-frame {
  &.csc-frame-rulerAfter {
    border-bottom: 1px solid @text-color;
  }
}

div.tx-pmmembers-pi1-container {
  display:      table;
  width:        100%;
  table-layout: fixed;

  > .row {
    display: table-row;

    > div {
      display:        table-cell;

      vertical-align: middle;
      text-align:     center;

      float:          none;

      > img, > a > img {
        margin: 0 auto;
      }
    }
  }
}

div.csc-textpic .csc-textpic-imagewrap figure, div.csc-textpic figure.csc-textpic-imagewrap {
  table-layout: fixed;
}

div.csc-textpic .csc-textpic-imagewrap img {
  width: 100%;
}

.pnGallery {
  .thumbnail {
    padding: 0;
    border:  0;
  }

  .clearfix();
}

.leftCol,
.middleCol,
.rightCol {
  margin-top: 10px;
}

.rightCol {
  .csc-textpic {
    img {
      .img-responsive();
    }
  }
}

.middleCol {
  div.csc-textpic .csc-textpic-imagewrap .csc-textpic-image,
  div.csc-textpic .csc-textpic-imagewrap .csc-textpic-imagerow-last .csc-textpic-image {
    margin-bottom: @line-height-computed;
  }

  .tx-solr {
    margin: @line-height-computed 0;
  }
}

.leftCol {
  > .inner {
    margin-top: -118px;
    background-color: #fff;
    border: 1px solid #d2deea;
    /*min-height: 128px;*/

    @media (max-width: @screen-xs-max) {
      margin-bottom: @grid-gutter-width;
    }
  }

  .subNavHeader {
    color:       @dehoga-orange;
    padding:     0 @nav-link-padding-left;
    font-weight: bold;
    font-size:   16px;
  }

  ul.nav {
    margin-bottom: 0;
    ul.nav {
      margin-bottom: 0;
    }
  }

  #youtube-api {
    .well-header {
      margin-bottom: (@line-height-computed/2);

      a {
        color:           @text-color;
        text-decoration: none;
        font-weight:     bold;
      }
    }
  }
}

.pam {
  padding: 0;
}

.phm {
  padding-left:  0;
  padding-right: 0;
}

#calendar-event .middleCol ul,
ul.ce-bullets,
ul.ce-menu,
.news-single .news-text-wrap ul,
.ce-bodytext ul,
.csc-frame ul,
.csc-default ul {
  &, ul {
    list-style: none;
    padding:    0;
    margin:     0 0 ((@line-height-computed/2)+4);
  }

  > li,
  ul > li {
    padding-left: 1.2em;
    text-indent:  -1.2em;

    &:before {
      content:                "\25A0";
      padding-right:          10px;
      -webkit-font-smoothing: antialiased;
      color:                  @brand-primary;
      font-size:              15px;
      position:               relative;
      top:                    -1px;
    }

    ol > li {
      text-indent: 0;
    }
  }
}

.csc-textpic-caption {
  font-size:  @font-size-small;
  font-style: italic;
}

.csc-layout-2 {
  .csc-textpic-caption {
    font-size:   @font-size-h4;
    font-weight: bold;
    font-style:  inherit;
  }
}

ul.parsley-errors-list,
ul.pagination,
div.treeview ul,
ul.treeview,
ul.media-list,
ul.list-unstyled,
ul.ce-uploads,
ul.csc-uploads {
  list-style: none;
  padding:    inherit;
  margin:     inherit;

  li {
    padding:     inherit;
    text-indent: inherit;

    &:before {
      content:   "";
      padding:   0;
      color:     inherit;
      font-size: inherit;
      position:  inherit;
      top:       inherit;
    }
  }
}

ul.list-unstyled {
  padding-left: 0;
}

ul.media-list {
  padding: 0;

  > li {
    padding: 0;
  }
}

div.treeview ul,
ul.treeview {
  li {
    padding: 2px 0pt 2px 95px;
  }
}

ul.parsley-errors-list {
  display:    none;
  padding:    0;
  margin-top: (@line-height-computed/2);

  &.filled {
    display: block;
  }

  > li {
    padding: (@line-height-computed/2);
  }
}

.mandatory {
  color: @alert-danger-text;
}

ul.pagination {
  margin: @line-height-computed 0;
}

ul.ce-uploads,
ul.csc-uploads {

  li {
    padding: 0;
  }

  &,
  &.ce-uploads-1,
  &.ce-uploads-2,
  &.csc-uploads-0,
  &.csc-uploads-1,
  &.csc-uploads-2 {
    span.ce-uploads-fileSize,
    span.csc-uploads-fileSize {
      float: right;
    }
  }

  &.ce-uploads-1,
  &.ce-uploads-2,
  &.csc-uploads-1,
  &.csc-uploads-2 {
    img {
      padding-right: 0;
      margin-right:  0;
    }

    span.ce-uploads-fileName,
    span.csc-uploads-fileName {
      margin-left: 24px;
    }

    span.ce-uploads-fileSize,
    span.csc-uploads-fileSize {
      padding-left: 12px;
    }
  }

  &.ce-uploads-2,
  &.csc-uploads-2 {
    .csc-upload-preview {
      margin-bottom: @line-height-computed;
    }

    > li {
      border-top:  1px solid @brand-primary;
      padding-top: @line-height-computed;

      &:first-child {
        border:      0;
        padding-top: 0;
      }
    }
  }
}

div.csc-textpic-border div.csc-textpic-imagewrap img {
  border: 1px solid @well-border;
}

.tx-seminars {
  .seminar-properties {
    margin-bottom: (@line-height-computed/2);

    img {
      margin: 0 auto;
    }

    .disabled {
      @tooltip-bg: @gray-lighter;
      @tooltip-arrow-color: @tooltip-bg;

      .tooltip-inner {
        color:            @text-color;
        background-color: @tooltip-bg;
      }

      .tooltip {
        &.top .tooltip-arrow {
          border-top-color: @tooltip-arrow-color;
        }
        &.top-left .tooltip-arrow {
          border-top-color: @tooltip-arrow-color;
        }
        &.top-right .tooltip-arrow {
          border-top-color: @tooltip-arrow-color;
        }
        &.right .tooltip-arrow {
          border-right-color: @tooltip-arrow-color;
        }
        &.left .tooltip-arrow {
          border-left-color: @tooltip-arrow-color;
        }
        &.bottom .tooltip-arrow {
          border-bottom-color: @tooltip-arrow-color;
        }
        &.bottom-left .tooltip-arrow {
          border-bottom-color: @tooltip-arrow-color;
        }
        &.bottom-right .tooltip-arrow {
          border-bottom-color: @tooltip-arrow-color;
        }
      }
    }
  }
}

@media (max-width: @grid-float-breakpoint-max) {
  .navbar-break {
    background-color: @brand-primary;
    color:            #fff;
    min-height:       40px;

    .navbar-toggle {
      float:        left;
      margin-left:  7px;
      margin-right: auto;

      .icon-bar {
        background-color: #fff;
      }
    }
  }

  .navbar-break-search {
    margin: (@line-height-computed/2) 0;
    .show();
  }

  .nav-justified {
    li.dropdown:hover {
      > .dropdown-menu {
        display: none;
      }
    }
  }
}

@media (min-width: @grid-float-breakpoint) {
  .navbar-break-search {
    .hidden();
  }
}

footer {
  margin-top:    @line-height-computed;
  margin-bottom: @line-height-computed;

  div.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
    margin-bottom: 0;
  }
}

@media (min-width: @screen-xs-max) and (max-width: @screen-md-max) {
  .csc-contacts {
    .csc-textpic-intext-left-nowrap {
      .csc-textpic-imagewrap {
        display: none;
      }
      .csc-textpic-text {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: @screen-md-max) {
  .video-js {
    width: 100% !important;
  }

  .csc-default {
    iframe {
      width: 100%;
    }
  }
}

.randomPageImages ul {
  list-style: none;
  margin:     0;
  padding:    0;

  .clearfix();

  > li {
    display:       none;
    margin-bottom: (@line-height-computed/2);
    padding-left:  0;
    text-indent:   0;

    &:before {
      content:       "";
      padding-right: 6px;
      position:      inherit;
      top:           auto;
      display:       none;
    }

    a {
      display:          block;
      background-color: #fff;
      border:           1px solid @well-border;
      padding:          5px;
      margin:           0;

      line-height:      110px;
      height:           110px;

      text-align:       center;
      vertical-align:   middle;

      > img {
        vertical-align: text-bottom;
        display:        inline;
      }
    }

    &.active {
      display: block;

      &:first-child,
      &:nth-child(3) {
        margin-left: 0;
      }

      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
}

@import "solr.less";
@import "typo3.dateblock.less";
@import "typo3.calendar.less";
@import "typo3.news.less";
@import "typo3.photodatabase.less";
@import "typo3.datepicker.less";
